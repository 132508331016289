const ROOT_URL = '/';
const GALLERY_URL = '/gallery/';
const PRODUCTS_URL = '/products';
const ABOUT_US_URL = '/about/';
const CONTACT_US_URL = '/contact/'
const MYTOKEN_COOKIE_NAME = 'userId';
const MYTOKEN_LOCALSTORAGE_KEY = 'userId';
const PRODUCTS_PATHS = []

    function saveToken(myToken) {
        document.cookie = `${MYTOKEN_COOKIE_NAME}=${myToken}; path=/`;
        localStorage.setItem(MYTOKEN_LOCALSTORAGE_KEY, myToken);
    }

    function retrieveToken() {
    return localStorage.getItem(MYTOKEN_LOCALSTORAGE_KEY) ?? '';
    }

    function redirectToPageIfNeeded(webPathname){
        const retrievedToken = retrieveToken();
        if (!webPathname.includes(retrievedToken)) {
            const newPagePath = `${webPathname}${retrievedToken}`;
            window.history.pushState(null, null, newPagePath);
        }
        
        return retrievedToken;
    }
    
    function handleDefault(tokenQuery) {
        saveToken(tokenQuery);
    }

    function sendDataGtm(){
    var userId = retrieveToken()
     if(userId.length > 0){
        const regex = /\?t=/gi;
        const result = userId.replace(regex, "");        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'userId' : result // Replace this with an actual User ID
        });
      };
    }
    
    const pathHandler = {
        handleRoot: (tokenQuery) => {
            if (tokenQuery) {
            saveToken(tokenQuery);
            } else {
            redirectToPageIfNeeded(ROOT_URL);
            }
        },
        handleServices: () => {
            redirectToPageIfNeeded(GALLERY_URL);
        },
        handleAboutUs: () => {
            redirectToPageIfNeeded(ABOUT_US_URL);
        },
        handleContactUs: () => {
            redirectToPageIfNeeded(CONTACT_US_URL);
        },
        handleProjects: () => {
            redirectToPageIfNeeded(PRODUCTS_URL);
        }
    };


    const routes = {
        [ROOT_URL]: pathHandler.handleRoot,
        [GALLERY_URL]: pathHandler.handleServices,
        [PRODUCTS_URL]: pathHandler.handleProjects,
        [ABOUT_US_URL]: pathHandler.handleAboutUs,
        [CONTACT_US_URL]: pathHandler.handleContactUs,
    };


    exports.onRouteUpdate = () => {

        const links = document.querySelectorAll('a[href*="/products"]');
        links.forEach(link => {
        var href = link.getAttribute('href');
        PRODUCTS_PATHS.push(href)
        });
        var filtered = PRODUCTS_PATHS.filter(function(item, pos) {
            return PRODUCTS_PATHS.indexOf(item) == pos;
        })

        filtered.map(function (path) {
            var retrievedToken = retrieveToken()
            if(path == window.location.pathname && window.location.pathname != '/Projects/'){
                const newPagePath = `${window.location.pathname}/${retrievedToken}`;
                window.history.pushState(null, null, newPagePath);
            }
        })

        const pathname = window.location.pathname;
        const tokenQuery = window.location.search;
        const handler = routes[pathname + tokenQuery] ?? handleDefault;
        handler(tokenQuery);
        sendDataGtm(tokenQuery)
    };
  
