exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-subscribe-index-js": () => import("./../../../src/pages/subscribe/index.js" /* webpackChunkName: "component---src-pages-subscribe-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */),
  "component---src-templates-category-category-js": () => import("./../../../src/templates/Category/Category.js" /* webpackChunkName: "component---src-templates-category-category-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/Product/Product.js" /* webpackChunkName: "component---src-templates-product-product-js" */)
}

