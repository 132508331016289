module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-522NS5SF","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ALULUX Website","short_name":"ALULUX","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/assets/images/alulux-favicon.svg","description":"ALULUX - Bringing Clarity To Your View.","lang":"en","dir":"ltr","orientation":"portrait","prefer_related_applications":false,"related_applications":[],"scope":"/","categories":["Business","Windows","Aluminium","Industrial"],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3ec8b8672bbced4b747c36eb0aec679"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
